/*********************************
*********** + Корзина ************
*********************************/

var Product = function (instance) {
    this.id = parseInt(instance.id);
    this.name = instance.name;
    this.price = parseInt(instance.price);
    this.formatPrice = instance.formatPrice;
    this.quantity = parseInt(instance.quantity);
    this.link = instance.link;
    this.src = instance.src;
    this.article = instance.article;
}

Product.prototype.sum = function () {
    return this.quantity * this.price;
}

var Cart = function () {
    this.sumDeliv = 0;
    this.products = [];
    this.sum = 0;
    this.xhr = null;
}

Cart.prototype.sendRequest = function (url, method, data, callback, additional) {
    if (this.xhr !== null) {
        this.xhr.abort();
    }
    
    var formData = {
        cart: data,
    }
    
    if (additional) {
        $.extend(formData, additional);
    }
    
    this.xhr = $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: url,
        method: method,
        data: formData,
        // dataType: 'json',
        success: function (data) {
            if (callback) {
                callback(JSON.parse(data));
                // callback(data);
            }
        }
    });
}

Cart.prototype.pull = function (url, success) {
    this.sendRequest(url, 'GET', null, success);
}

Cart.prototype.push = function (url, data, cb, additional) {
    this.sendRequest(url, 'GET', data, cb, additional);
}

Cart.prototype.totalSum = function () {
    this.sum = 0;

    for (var i = 0; i < this.products.length; i++) {
        this.sum += this.calc(this.products[i].price, this.products[i].quantity);
    }

    this.sum += this.sumDeliv;

    return this.sum;
}

Cart.prototype.calc = function (cost, quantity) {
    var sum = parseInt(cost) * parseInt(quantity);
    return sum;
}

Cart.prototype.changeCount = function (id, quantity) {
    for (var i = 0; i < this.products.length; ++i) {
        if (this.products[i].id == id) {
            this.products[i].quantity = quantity;
        }
    }
}

Cart.prototype.countInCart = function () {
    var sum = 0;

    for (var i = 0; i < this.products.length; ++i) {
        sum += this.products[i].quantity * this.products[i].price;
    }

    return sum;
}

Cart.prototype.addProduct = function (product, add) {
    for (var i in this.products) {
        if (this.products[i].id === product.id) {
            if (typeof add !== 'undefined') {
                product.quantity = this.products[i].quantity + add;
            }
            this.products[i] = product;
            return this;
        }
    }

    this.products.push(product);
    return this;
}

Cart.prototype.delProduct = function (id) {
    for (var i = 0; i < this.products.length; ++i) {
        if (this.products[i].id === id) {
            this.products.splice(i, 1);
        }
    }
    return this.products;
}